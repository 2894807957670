import { SmetaMergeElementState, SmetaMergeElementType } from "@/assets/model/smeta/merge/SmetaMergedElement" 

export const SmetaMerge = Object.freeze({
  calcOffset(type) {
    if (type == SmetaMergeElementType.TOPIC || type == SmetaMergeElementType.NODE) return 8
    return 12
  },

  getMarginByLevel(item) {
    if (item.isNode && !item.children.length) return `margin-left: ${item.element.level * this.calcOffset(item.element.type) + 24}px`
    if (!item.isNode && !item.children.length) return `margin-left: ${item.element.level * this.calcOffset(item.element.type) + 8}px`
    return ''
  },

  getTranslate(item) {
    switch (item.element.state) {
      case SmetaMergeElementState.OK: return "Общий"
      case SmetaMergeElementState.DELETE: return item.accepted ? "Отменить удаление" : "Применить удаление"
      case SmetaMergeElementState.INSERT: return item.accepted ? "Отменить добавление" : "Применить добавление"
      case SmetaMergeElementState.UPDATE: return item.accepted ? "Отменить обновление" : "Применить обновление"
    }
  },

  getAlertTitle(item) {
    return item.getTitle()
  },

  getColor(state) {
    switch (state) {
      case SmetaMergeElementState.OK: return "var(--v-primary-darken2)"
      case SmetaMergeElementState.DELETE: return "#ED5E68"
      case SmetaMergeElementState.INSERT: return "#81B635"
      case SmetaMergeElementState.UPDATE: return "var(--v-accent-base)"
    }
  },

  getIcon(item) {
    switch (item.element.state) {
      case SmetaMergeElementState.OK: return "mdi-content-duplicate"
      case SmetaMergeElementState.DELETE: return item.accepted ? "$arrow-u-down-left-bold-box-icon" : "mdi-close-box-outline"
      case SmetaMergeElementState.INSERT: return item.accepted ? "$arrow-u-down-left-bold-box-icon" : "mdi-arrow-up-bold-box-outline"
      case SmetaMergeElementState.UPDATE: return item.accepted ? "$arrow-u-down-left-bold-box-icon" : "mdi-autorenew"
    }
  },
})