<template lang="pug">
v-list
  v-list-item( test-id="smeta-filter-withRules" )
    v-switch.pa-0.ma-0( v-model="localORules" dense color="#3b93af" hide-details @change="$emit('update:oRules', $event)")
      template( v-slot:label ) 
        .smeta-tree-filter-list__text {{$t("section.classification.menuClassification.withRules")}} ({{ counts.rulesCount }})
  v-list-item
    v-switch.pa-0.ma-0( v-model="localOValidRule" dense color="#3b93af" hide-details @change="$emit('update:oValidRule', $event)")
      template( v-slot:label ) 
        .smeta-tree-filter-list__text {{$t("section.classification.menuClassification.withValidRules" )}} ({{ counts.validRulesCount }})
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    oRules: { type: Boolean, default: false },
    oValidRule: { type: Boolean, default: false }
  },

  data() {
    return {
      localORules: this.oRules,
      localOValidRule: this.oValidRule,
    }
  },

  computed: {
    ...mapState('smeta', ['counts'])
  }
}
</script>

<style scoped>
.smeta-tree-filter-list__text {
  color:#3b93af;
  font-size: 10px;
  text-transform: uppercase;
}
</style>