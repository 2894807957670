<template lang="pug">
  v-row(no-gutters)
    v-col
      app-text {{ item.code }} - {{ item.caption }}
    v-col(cols="auto")
      template(v-if="classnode")
        v-icon(v-if="classnode.hasRules" color="#0f0" :title="$t('section.classification.menuClassification.grandSmeta.classifierFound')") mdi-check-circle
        v-icon(v-else color="#FFA500" :title="$t('section.classification.menuClassification.grandSmeta.classifierFoundNoRules')") mdi-alert
      v-icon(v-else color="#f00" :title="$t('section.classification.menuClassification.grandSmeta.classifierPositionNotFound')") mdi-cancel
      

</template>

<script>

import { mapGetters } from "vuex"

export default {
  name:'GrandSmetaPosition',

  components: {
    
  },

  props: {
    item: Object
  },

  data() {
    return {
      
    }
  },

  mounted() {
    
  },

  computed: {
    ...mapGetters('smeta',['getNode']),

    classnode(){
      if (!this.item.classificatorNodeUuid) return null
      return this.getNode(this.item.classificatorNodeUuid)
    }
  },

  watch: {
    
  },

  methods: {
    
  }
}
</script>

<style scoped>

</style>