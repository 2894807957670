<template lang="pug">
app-panel.pl-2( padding="0px" )
  app-floating-panels-section( style="height: calc(100% - 26px) !important; overflow: auto; overflow-x: hidden" )
    app-floating-panel( ref="floatingPanel" :title="(selectedAttribute && selectedAttribute.name) ? $t('section.classification.attribute.settingAttribute', {name: selectedAttribute.name}) : $t('section.classification.attribute.settingAttribut')"
                        bodyMinHeight="150" :startHeight="80" overflowBody="auto" :bodyColor="'#fff'" :settings="floatingPanels('ATTRIBUTE_SETTINGS')" )
      attribute-form.base-scroll-body( v-if="selectedAttribute" ref="attributeForm" :attribute="selectedAttribute" @toggle="toggleVisible" :units="unitTypes" :powers="powerTypes" )
      div.ma-auto( v-else style="color: rgba(0, 0, 0, 0.38)") {{ $t('section.classification.attribute.selectAttribute') }} 

    app-floating-panel( :title="$t('section.classification.attribute.linkedClassifier')" bodyMinHeight="150" :startHeight="50" overflowBody="auto" :bodyColor="'#fff'" :settings="floatingPanels('LINKED_ELEMENTS')" )
      template( v-if="selectedAttribute" )
        div.px-4.py-2.base-scroll-body
          div.elements( v-for="(item, index) in selectedAttributeClassificatorNodeWithProjectClassificatorName" )
            div.mb-auto
              v-icon(v-if="item.founded" color="#0f0" size="16") mdi-circle-medium
              v-icon(v-else color="#ffff00" size="16") mdi-circle-medium
            div( v-if="item.founded" @contextmenu.prevent="showClassificatorMenu($event, item, index)" ) {{ item.system }} - {{ item.title }}
            div( v-else @contextmenu.prevent="showClassificatorMenu($event, item, index)" ) {{ item.system }} - {{ item.value }}
            div.mb-auto.ml-auto
              v-icon( color='var(--v-primary-base)' :disabled='!hasClassificatorControlUniversalAttr' small @click.stop='showClassificatorMenu($event, item, index)' ) more_vert

          v-btn.mt-4.px-0( text color="accent" :disabled='!hasClassificatorControlUniversalAttr' x-small @click.stop="showBindingElementsDialog" )
            v-icon.mr-1 mdi-plus-circle-outline
            span {{ $t('section.classification.attribute.addBinding') }}
      div.d-flex.text( v-else ) {{ $t('section.classification.attribute.selectAttribute') }} 

      classificator-node-form-dialog(v-model="dialog.bindingElements" @addClassificatorNode="addClassificatorNode($event)")

      app-menu( ref="classificatorMenu" :menu="classificatorMenu" )
    
    app-floating-panel( v-if='showPanel' ref="hightLightGroups" :title="$t('section.collision.rulesFounder.viewElements')"  bodyMinHeight="150" :startHeight="80" overflowBody="auto" 
                        :bodyColor="'#fff'" :settings="floatingPanels('VIEWING_ITEMS')")
      hight-light-groups( ref="hightLightGroupsRefs" )
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import AttributeForm from './AttributeForm'
import HightLightGroups from '../../../components/element/HightLightGroups'
import ClassificatorNodeFormDialog from './ClassificatorNodeFormDialog.vue'
import { useHighlightGroupsStore } from '@/pinia/smeta/highlightGroups.store'

// const projectClassificatorHash = {}
const highlighGroupsStore = useHighlightGroupsStore()

export default {
  name: 'attribute-setting-form',

  components: {
    AttributeForm,
    HightLightGroups,
    ClassificatorNodeFormDialog,
  },

  data() {
    return {
      sellectedAttribute: null,
      classificator: {
        uuid: null
      },
      
      dialog: {
        bindingElements: false,
      },
      shownMenuClassificatorItem: null,
      shownMenuClassificatorIdx: null,
    }
  },

  mounted() {
    this.setShowOnlyRules(true)
  },

  updated() {
    this.openPanel()
  },

  watch: {
    'selectedAttribute'() {
      if (this.selectedAttribute) {
        this.loadElementsForHighLigths()
      }
    },
  },
  
  computed: {
    ...mapGetters('floatingPanels', ['floatingPanels']),
    ...mapGetters("smeta", ["treeAllVisibleNodes"]),
    ...mapState('projectDict', ['selectedAttribute']),
    ...mapState('elements', ['unitTypes', 'powerTypes']),
    ...mapGetters('smeta', ['getNode']),
    ...mapGetters('project', ['projectUuid']),
    ...mapGetters("projectPermissions", ['hasClassificatorControlUniversalAttr']),
    ...mapState('project', ['projectSettings']),
    ...mapState('projectDict', ['showPanel']),
    ...mapState('smeta', ['projectClassificators']),
    ...mapGetters("projectPermissions", ['hasClassificatorControlUniversalAttr']),

    classificatorMenu() {
      let menu = []
      if(this.shownMenuClassificatorItem?.founded){
        menu.push({ title: this.$t('section.classification.attribute.goClassifier'), action: () => this.switchToSmetaRuleEdit(this.shownMenuClassificatorItem) })
        menu.push({ title: this.$t('section.classification.attribute.delete'), action: () => this.deleteClassificator(this.shownMenuClassificatorIdx) })
      }
      else {
        menu.push({ title: this.$t('section.classification.attribute.delete'), action: () => this.deleteClassificator(this.shownMenuClassificatorIdx) })
      }
      return menu
    },

    selectedAttributeClassificatorNodeWithProjectClassificatorName() {
      return this.selectedAttribute.classificationType
      // return this.selectedAttribute.classificatorNode.map(classificatorNode => {
      //   const projectClassificator = projectClassificatorHash[classificatorNode.projectClassificatorUuid] || this.projectClassificators.find(classificator => classificator.uuid === classificatorNode.projectClassificatorUuid)
      //   projectClassificatorHash[projectClassificator.uuid] = projectClassificator

      //   let classificatorNodeWithProjectClassificatorName = { ...classificatorNode }
      //   classificatorNodeWithProjectClassificatorName.projectClassificatorName = projectClassificator.title

      //   return classificatorNodeWithProjectClassificatorName
      // })
    },

  },

  methods:{
    ...mapMutations("smeta", ['setShowOnlyRules', 'setExpandAfterLoadUuid']),
    ...mapActions("projectDict", ["editAttribute"]),
    ...mapMutations('projectDict', ['setShowPanel']),

    addClassificatorNode(event){
      this.selectedAttribute.classificationType.push(...event)
      this.saveElement()
      this.dialog.bindingElements = false
    },

    deleteClassificator(classificatorIdx) {
      this.selectedAttribute.classificationType.splice(classificatorIdx, 1)
      this.saveElement()
    },

    async toggleVisible() {
      this.setShowPanel(!this.showPanel)
      await this.$nextTick()
      if (this.showPanel) {
        this.loadElementsForHighLigths()
      }
      else highlighGroupsStore.resetState()
    },

    openPanel() {
      // const attributeListPanel = this.$refs['floatingPanel']
      if (this.selectedAttribute) {
        // !attributeListPanel?.openedPanel && attributeListPanel?.openBody()
        this.$refs['attributeForm']?.resetValidationForm()
      }
      return this.$nextTick()
    },

    // openPanelHightLightGroups() {
    //   // const hightLightGroups = this.$refs['hightLightGroups']
    //   // if (this.showPanel) {
    //   //   !hightLightGroups?.openedPanel && hightLightGroups?.openBody()
    //   // }
    //   return this.$nextTick()
    // },

    showBindingElementsDialog() {
      this.dialog.bindingElements = true
      this.classificator.uuid = ''
    },

    saveElement() {
      const attribute={
        ...this.selectedAttribute
      }
      this.editAttribute(attribute)
    },

    showClassificatorMenu(event, item, index) {
      if(this.hasClassificatorControlUniversalAttr) this.$refs.classificatorMenu.show(event)
      this.shownMenuClassificatorItem = item
      this.shownMenuClassificatorIdx = index
    },

    switchToSmetaRuleEdit(classificationItem) {
      let ps = JSON.parse(JSON.stringify(this.projectSettings))
      ps.projectClassificator = classificationItem.systemUuid
      this.$store.dispatch('project/updateSettingsProjectClassificator', ps)
      this.setExpandAfterLoadUuid(classificationItem.uuid)
      this.$emit('scrollTo', classificationItem)
    },

    loadElementsForHighLigths() {
      highlighGroupsStore.loadDictHighLigthsGroup().then(() => {
        this.$refs?.hightLightGroupsRefs?.init()
        this.$refs.hightLightGroupsRefs?.changeKeepElements()
      })
      // this.openPanelHightLightGroups()
    },
     
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-input--selection-controls {
  margin-top: 0 !important;
}

.elements {
  color: rgba(0,0,0,.87);
  font-size: 12px;
  display: flex;
  align-items: center;
}
</style>