<template lang="pug">
  .node(v-bind:class="{ 'node--onedit': isOnEdit, 'blink_me': source.uuid === scrollTo }")
    .node__root
      .node__level( v-for="index in level" :key="index" )
      
      .node__level( v-if="source.isEndpoint" )
      v-icon.node__toggle( v-else tag="button" :class="toggleClass" light @click.stop="toggleOpened(source)") arrow_drop_down
      
      v-hover(v-slot:default="{ hover }" close-delay="150" )
        .node__content
          .node__label(:class="{hasRules : isHasRules}" @click="itemClick" @contextmenu.prevent="showItemMenu($event)" :title="source.pressmark ? source.pressmark+' - '+source.title : source.title")
            span(v-if="source.logicValidy")
              v-icon(v-if="source.logicValidy.valid" color="#0f0" size="16") mdi-circle-medium

            .text-over
              span(v-if="source.pressmark" :class="{ hasgrandsmeta: hasGrandSmeta }") {{ source.pressmark }}
              | {{ source.pressmark ? ` - ${source.title}` : source.title }}
            v-icon.ml-2(v-if="showIconMenu" :color="hover ? '#000' : 'transparent'" @click.stop="showItemMenu($event)" small) more_vert
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator";
export default {
  name:'smeta-tree-item',

  components: {
    
  },

  props: {
    menu: {
      type: Array,
      default: null
    },
    source: {
      type: Object,
      default () {
        return {}
      }
    },
    scrollTo: String,
    showMenu: Function
  },

  data() {
    return {
      
    }
  },

  mounted() {
    // console.log(this.source)
  },

  computed: {
    ...mapState('smeta', ['editRuleItem','searchText']),
    ...mapGetters('smeta', ['isOpened', 'hasRules']),

    ...mapGetters("projectPermissions", ['hasClassificatorCreate', 'hasClassificatorImportExport', 'hasClassificatorDownloadReport', 'hasClassificatorControlUniversalAttr', 'hasClassificatorCreateRule', 'hasClassificatorDeleteRule']),

    showIconMenu () {
      if (!this.menu) return false
      switch (this.source.nodeType.name) {
        case 'TOPIC':
          return this.hasClassificatorCreate || this.hasClassificatorImportExport || this.hasClassificatorControlUniversalAttr || this.hasClassificatorDownloadReport
        case 'NODE':
          return this.hasClassificatorCreateRule || this.hasClassificatorImportExport || this.hasClassificatorCreate || this.hasClassificatorDownloadReport
        case 'RULE': 
          return this.hasClassificatorCreateRule || this.hasClassificatorDeleteRule || this.hasClassificatorDownloadReport
        default:
          return this.hasClassificatorCreateRule 
      }
    },

    isOpen () {
      return this.isOpened(this.source)
    },

    isOnEdit () {
      let b = false
      if (this.editRuleItem == null) b = false
      else if (this.editRuleItem.uuid && this.editRuleItem.uuid == this.source.uuid) b = true
      return b
    },

    isHasRules(){
      // return this.source.nodeType.name == 'NODE' && this.source.hasRules
      return this.hasRules(this.source)
    },

    toggleClass () {
      return this.isOpen ? 'node__toggle--open' : 'node__toggle--close'
    },

    level () {
      let lev = this.source.level ?? 0
      if (this.searchText) lev = lev - (lev-1)
      return this.source.children?.length > 0 ? lev : ++lev
    },

    hasGrandSmeta(){
      return this.source.grandSmetaPosition
    }
  },

  watch: {
    
  },

  methods: {
    ...mapMutations('smeta', ['setEditRuleItem']),
    ...mapActions('smeta', ['toggleOpened']),

    itemClick(){
      if (this.source.isRule) {
        if (this.editRuleItem && this.editRuleItem.uuid == this.source.uuid) this.setEditRuleItem(null)
        else {
          XeokitMediator.ElementsSelection.pickElement(null)
          this.setEditRuleItem(null)
          this.setEditRuleItem(this.source)
        }
      } else {
        this.setEditRuleItem(null)
        if (this.source.hasChilds) this.toggleOpened(this.source)
      }
    },

    showItemMenu(event){
      // this.$emit('showItemMenu', event, this.source)
      this.showMenu(event, this.source)
    }
  }
}
</script>

<style lang="scss" scoped>
.node {
  color: rgba(0,0,0,.87);
}
.node:hover {
  background-color: var(--v-primary-lighten5);
}
.node--hightlighted {
  background-color: var(--v-primary-lighten5);
}
.node.node--onedit {
  background-color: var(--v-primary-lighten4);
}
.node__root {
  display: flex;
  align-items: center;
  min-height: 24px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.node__root::before {
  background-color: currentColor;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: .3s cubic-bezier(.25,.8,.5,1);
}

.node__root::after {
  content: "";
  font-size: 0;
  min-height: inherit;
}

.node__toggle {
  font-size: 16px;

  &--open {
    transform: none;
  }

  &--close {
    transform: rotate(-90deg);
  }
}

.node__toggle::after {
  background-color: currentColor;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: scale(1.3);
  width: 100%;
  transition: opacity .2s cubic-bezier(.4,0,.6,1);
}

.node__content {
  align-items: center;
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 0;
  margin-left: 6px;
  padding-right: 4px;
}

.node__label {
  flex: 1;
  font-size: 12px;
  display: flex;
}

.text-over {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.node__label.hasRules {
  font-weight: bold;
}

.node__stat {
  font-size: 12px;
  color: var(--v-accent-darken3);
  padding: 0 4px;
}

.node__level {
  width: 8px;
}

.node__checkbox {
  color: var(--v-primary-lighten4);
  caret-color: var(--v-primary-lighten4);
  font-size: 16px;
}
.hasgrandsmeta {
  color: green;
  font-weight: bold;
}
.blink_me {
  animation: myAnim 1s linear 0s 1 normal none;
}
@keyframes myAnim {
  80% {
    background: var(--v-accent-base);
  }
}
</style>