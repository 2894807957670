<template lang="pug">
app-dialog( v-model="$_show" :header="$t('section.classification.menuClassification.exportSmetaSimple')" )
  app-section
    app-text {{$t('section.classification.menuClassification.preparingFileDia')}}
    v-progress-linear(indeterminate)
    app-text {{ error }}
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable'

export default {
  name: 'ExportGrandSmetaDialog', 
  mixins: [DialogToggable],

  props: {
    error: {
      type: String, 
      default: ''
    }
  }
}
</script>