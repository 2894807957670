<template lang="pug">
  div
    v-progress-linear(v-if="treeLoading" indeterminate)

    v-virtual-scroll.mt-3(
        :items="treeAllVisibleNodesForSearch(nodeList)"
        height="300"
        item-height="24"
      )
        template(v-slot:default="{ item }")
          SmetaTreeItem(:source="item" @showItemMenu="showItemMenu($event, item)")
</template>

<script>

import SmetaTreeItem from "./SmetaTreeItem"

import { api } from "@/api";

import { mapState, mapGetters, mapMutations } from 'vuex'
import { SmetaTreeStateCache } from '@/assets/model/smeta'; //ClassificatorNode

let searchSmetaCache = new SmetaTreeStateCache

export default {

  components: {
    SmetaTreeItem
  },

  props: {
    searchText: String
  },

  data () {
    return {
      nodeList: [],
      treeLoading: false
    }
  },

  watch: {
    searchText () {
      this.searchClassificatorNode()
      // this.resetSmeta()
    },
  },

  computed: {

    ...mapState('smeta', ['projectClassificators', 'projectClassificatorUuid','grandSmeta']),
    ...mapGetters('smeta', ['treeAllVisibleNodesForSearch','grandSmetaPositionByClassificator']),

    selectedClassificator () {
      let classificator = this.projectClassificators.find(classificator => classificator.uuid == this.projectClassificatorUuid)
  
      return classificator
    }
  },

  methods: {

    ...mapMutations('smeta', ['resetSmeta']),

    searchClassificatorNode () {
      this.treeLoading = true
      
      let query = `?uuid=${this.selectedClassificator.uuid}&text=${this.searchText}`
      api.smeta.searchInClassificatorOnlyByPressmark(query).then((data) => {
        searchSmetaCache.saveTree(data)
        searchSmetaCache.bindGrandsmeta(this.grandSmeta)
        this.nodeList = searchSmetaCache.tree.items
        this.treeLoading = false
      })
    },

    showDeleteNode (item) {
      this.deleteNode.node = item
      this.deleteNode.dialog = true
    },

    showItemMenu (event, item) {
      this.$emit('showItemMenu', event, item)
    }

  },

  mounted () {
    this.searchClassificatorNode()
  }

}
</script>

<style scoped lang="scss">

</style>