<template lang="pug">
  app-dialog( v-model="$_show"
              :header="$t('section.classification.menuClassification.grandSmeta.uploadedSmeta')"
              :closeOnConfirm="false"
              width=500 :confirmText="$t('button.upload')" @confirm="upload" :confirmDisabled="uploading" )
    app-section
      v-form( ref="uploadForm" )
        app-file-input(v-model="smetaFile" formats=".xml" :label="$t('section.classification.menuClassification.grandSmeta.file')" light :rules="[rules.required]")

      div(v-if="uploading")
        app-text {{ $t('section.classification.menuClassification.grandSmeta.uploadingSmeta') }}
        v-progress-linear(indeterminate)
      app-text.mt-2(v-if="errorMessage") {{ errorMessage }}
</template>

<script>
import { mapState, mapActions } from "vuex"
import { api } from "@/api"
import DialogToggable from "@/components/app/DialogToggable"

export default {
  name:'GrandSmetaUpload',

  mixins: [DialogToggable],

  components: {
    
  },

  props: {
    projectClassificatorUuid: String
  },

  data() {
    return {
      projectClassificatorSelected: null,
      smetaFile: null,
      uploading: false,
      errorMessage: null,

      rules: {
        required: (value) => !!value || this.$t("error.require")
      }
    }
  },

  mounted() {
    
  },

  computed: {
    ...mapState('project', ['project']),
  },

  watch: {
    
  },

  methods: {
    ...mapActions('smeta',['LOAD_SMETA']),
    upload(){
      if (this.$refs.uploadForm.validate()) {
        this.uploading = true
        let formData = new FormData()
        formData.append('projectUuid', this.project.uuid);
        formData.append('projectclassificatorUuid', this.projectClassificatorUuid);
        formData.append('file', this.smetaFile);
        api.smeta.uploadGrandSmeta(formData).then(() => {
          
          this.uploading = false
          this.$_show = false
          this.smetaFile = null


          this.LOAD_SMETA(this.projectClassificatorUuid)
        }).catch(() => {
          this.uploading = false
          this.errorMessage = this.$t('section.classification.menuClassification.errorMessage')
        })
      }
    }
  }
}
</script>

<style scoped>

</style>