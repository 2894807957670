<template lang="pug">
div( style="height: 100%" )
  div(style="height: 100%")
    .d-flex.mb-2.justify-space-between
      v-btn.dict-button.px-1( color="accent" dense text x-small @click="toggleMassFolding" test-id="smeta-expand-collapse")
        v-icon.mr-1( color="accent" size="16" left ) unfold_more
        | {{ areAllTreeNodesOpened ? $t('section.elementTree.axis.collapseTree') : $t('section.elementTree.axis.expandTree') }}
      smeta-tree-filter-menu
        SmetaConnectionsTreeFilterList( :filters="filters" )
    div( ref="smeta-connections-virtual-list" style="height: 100%")
      virtual-list.fill-height.overflow-auto.base-scroll-body(
        ref='virtual-list'
        class='node-list'
        :keeps="treeElementKeeps",
        :estimate-size="treeElementHeight"
        :data-key="'uuid'"
        :data-component="smetaConnectionsTreeItemComponent"
        :data-sources="connectionsTreeAllVisibleNodes || []"
        test-id="smeta-virtual-list"
      )
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SmetaTreeFilterMenu from '../filters/SmetaTreeFilterMenu.vue';
import VirtualList from 'vue-virtual-scroll-list'
import SmetaConnectionsTreeItem from './SmetaConnectionsTreeItem.vue';
import SmetaConnectionsTreeFilterList from '../filters/SmetaConnectionsTreeFilterList.vue';
import { useSmetaConnectionsStore } from '@/pinia/smeta/smetaConnections.store';

const smetaConnectionsStore = useSmetaConnectionsStore()

export default {
  components: {
    SmetaTreeFilterMenu,
    VirtualList,
    SmetaConnectionsTreeItem,
    SmetaConnectionsTreeFilterList
  },

  data() {
    return {
      treeElementHeight: 24,
      treeElementKeeps: 20,
      smetaConnectionsTreeItemComponent: SmetaConnectionsTreeItem,
    }
  },

  computed: {
    ...mapGetters('smeta', ['areAllTreeNodesOpened', 'connectionsTreeAllVisibleNodes']),
    filters: () => smetaConnectionsStore.filters
  },

  methods: {
    ...mapActions('smeta', ['collapseAllTreeNodes', 'expandAllTreeNodes']),

    toggleMassFolding () {
      this.areAllTreeNodesOpened ? this.collapseAllTreeNodes() : this.expandAllTreeNodes()
    },
  },

  mounted() {
    const smetaTreeContainer = this.$refs['smeta-connections-virtual-list']
    if(smetaTreeContainer != undefined){
      this.pageResizeObserver = new ResizeObserver(() => {
        this.treeElementKeeps = Math.ceil(smetaTreeContainer.clientHeight / this.treeElementHeight) + 10
      })
      this.pageResizeObserver.observe(smetaTreeContainer)
    }
  },

  beforeDestroy() {
    smetaConnectionsStore.selectedNode = null
  }
}
</script>

<style scoped>
  .node-list {
    height: calc(99% - 74px);
  }
  .overflow-auto {
    overflow-x: hidden !important;
  }
</style>