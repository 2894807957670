<template lang="pug">
  app-dialog(v-model="$_show" :header="$t('section.classification.menuClassification.budgetItem')" width="800")
    app-section
      div.mb-3
        div
          app-text caption: {{ grandSmetaPosition.caption }}
        div
          app-text code: {{ grandSmetaPosition.code }}
        div
          app-text(v-if="grandSmetaPosition.priceBase") priceBase: em - {{ grandSmetaPosition.priceBase.em }}; mt - {{ grandSmetaPosition.priceBase.mt }}; oz - {{ grandSmetaPosition.priceBase.oz }}; pz - {{ grandSmetaPosition.priceBase.pz }}; zm - {{ grandSmetaPosition.priceBase.zm }}; value - {{ grandSmetaPosition.priceBase.value }}
          app-text(v-else) priceBase: -
        div
          app-text quantity: fx - {{ grandSmetaPosition.quantity.fx }}; kUnit - {{ grandSmetaPosition.quantity.kUnit }}; result - {{ grandSmetaPosition.quantity.result }}

        v-divider.my-5(light)

        div
          app-text {{ $t('module.task.smeta.works') }}:
          div(v-for="work in grandSmetaPosition.works") 
            app-text {{ work.caption }}

        v-divider.my-5(light)
        
        app-section-header-tabs.mb-1( :items="types" :value="selectedType.value" @select="onSelect" )
        v-data-table#copoundTable(
          light
          dense
          :headers="headers"
          :items="resourcesFiltred"
          item-key="uuid"
          :no-data-text="$t('module.task.smeta.noDataSelectedType')"
        )
          template(v-slot:item="{ item }")
            v-hover( v-slot:default="{ hover }" )
              tr.compRow(v-if="item")
                td {{ item.code }}
                td {{ item.caption }}
                td {{ item.priceBase ? item.priceBase.value : ''}}
                td {{ item.units }}
                td {{ item.quantity }}
    
        
</template>

<script>
import DialogToggable from "@/components/app/DialogToggable"
export default {
  mixins: [DialogToggable],
  components: {

  },

  props: {
    grandSmetaPosition: Object
  },

  data() {
    return {
      types:[
        {name: 'WORK', title: this.$t('module.task.smeta.laborCosts'), value: 0},
        {name: 'MACHINE', title: this.$t('module.task.smeta.machines'), value: 1},
        {name: 'MATERIAL', title: this.$t('module.task.smeta.materials'), value: 2}
      ],
      selectedType: {name: 'WORK', title: this.$t('module.task.smeta.laborCosts'), value: 0}
    }
  },

  computed: {
    headers () {
      return [
        {text: this.$t('token.form.code'), align: 'start', sortable: false},
        {text: this.$t('section.iot.name'),  align: 'start', sortable: false},
        {text: 'value',  align: 'start', sortable: false},
        {text: 'units',  align: 'start', sortable: false},
        {text: 'quantity',  align: 'start', sortable: false}
      ]
    },

    resourcesFiltred() {
      if (this.selectedType.name == 'WORK') return this.grandSmetaPosition?.resources?.filter(el => el?.type?.name === 'TZR' || el.type.name === 'TZM')
      if (this.selectedType.name == 'MACHINE') return this.grandSmetaPosition?.resources?.filter(el => el?.type?.name === 'MCH')
      if (this.selectedType.name == 'MATERIAL') return this.grandSmetaPosition?.resources?.filter(el => el?.type?.name === 'MAT')
      return []
    }
  },

  methods: {
    onSelect (item) {
      this.selectedType = item
    },
  }
}
</script>

<style scoped>

</style>