<template lang="pug">
  app-dialog( v-model="$_show"
              :header="$t('section.classification.menuClassification.addCompound', {name: $t(nodeType.title)})"
              width="700" complex
              :confirmText="$t('button.save')" :confirm-disabled="(!compound.rate || !compound.rate.trim()) || isNaN(+compound.rate)" @confirm="handleSave" )
    app-section( :rounded="false" )
      .d-flex
        app-text-field( v-model="searchText"
                        @keyup.enter="search"
                        :label="$t('section.classification.menuClassification.codeSearch')"
                        outlined dense clearable hide-details test-id="calss-code-search")
        app-button.ml-2(@click="search" color="accent" height="40") {{ $t('section.elementTree.search') }}

      v-form( ref="compoundForm" )
        div.base-scroll-body.mh300.my-2
          div.my-3( v-if="!searchMode" )
            v-treeview.__project_view_tree(
              dense
              light
              hoverable
              item-disabled="disabled"
              :active.sync="activeItem"
              open-on-click
              :open.sync="openedNodes"
              item-key="uuid"
              :items="treeList"
              :load-children="loadChildrens"
              return-object )

              template( v-slot:label="{ item }" )
                div( @click="selectItem(item)" ) {{ item.pressmark ? item.pressmark+' - ' : '' }}{{ item.title }}
                
          div.my-3( v-if="searchMode" )
            v-skeleton-loader( v-if="searchLoading" type='list-item-three-line' light )
            v-list( v-if="!searchLoading" light dense )
              v-list-item-group( v-model="searchSelected" )
                v-list-item( v-for="item in searchList" :key="item.uuid" :disabled="item.nodeType.value != nodeType.value" )
                  span(v-html="itemHighlightedText(item)")

        v-alert( v-if="nodeError" dense text type="error" ) {{ $t(nodeErrorText) }}

        app-text-field( v-model="compound.rate" :label="$t('section.classification.menuClassification.outgo')" outlined dense :rules="[rules.required, rules.double]" test-id="classification-outgo" )

        v-checkbox.mx-n1( v-model="compound.showInSmeta" dense hide-details light )
          template( v-slot:label )
            app-text.mx-n1 {{$t('section.classification.menuClassification.displaySmeta')}}
</template>

<script>
import DialogToggable from "@/components/app/DialogToggable"
import { mapState } from "vuex";
import { api } from "@/api";

export default {
  mixins: [DialogToggable],
  components: {

  },

  props: {
    nodeType: Object,
    compounds: Array
  },

  data() {
    return {
      compound: {
        classificatorNode:null,
        node: null,
        rate: null,
        showInSmeta: false
      },

      treeList: [],
      openedNodes: [],
      activeItem: [],

      searchText: null,
      searchLoading: false,
      searchMode: false,
      searchList: [],
      searchSelected: null,

      nodeError: false,
      nodeErrorText: 'section.classification.menuClassification.classNotSelected',
      rules: {
        required: (value) => !!value || this.$t("error.require"),
        double: (value) => (!!value && /^\d+(.\d+)?$/.test(value)) || this.$t('section.worm.wholeOrFractionalNumber'),
      }
    }
  },

  mounted() {
    this.compound.classificatorNode = this.editClass
    this.listProjectClassofocators()
  },

  computed: {
    ...mapState("project", ["project"]),
    ...mapState("smeta", ["editClass"]),
  },

  watch: {
    $_show(val){
      this.nodeError = false
      this.treeList = []
      this.openedNodes = []
      this.activeItem = []
      this.searchSelected = null
      this.compound = {
        classificatorNode:null,
        node: null,
        rate: null,
        showInSmeta: false
      }
      if (val) this.listProjectClassofocators()
    },

    searchText(val){
      if (val && val.trim() != '') {
        this.searchSelected = null
        this.activeItem = []
      } else {
        this.searchMode = false
        this.searchList = []
        this.searchSelected = null
      }
    },

    searchSelected(val){
      if (val != null) this.compound.node = this.searchList[val]
      else this.compound.node = null
    },

    activeItem(val){
      if (val && val[0]) this.compound.node = val[0]
      else this.compound.node = null
    },

    'compound.node'(val){
      if (val) this.nodeError = false
      else this.nodeError = true
    }
  },

  methods: {
    search() {
      this.searchMode = true
      this.searchLoading = true
      api.smeta.searchForCompound(this.project.uuid, this.searchText).then(data=>{
        this.searchList = data
        this.searchLoading = false
      })
    },

    itemHighlightedText(item) {
      const highlightWords = this.searchText.trim().split(' ')
      const highlightRegexp = new RegExp(highlightWords.join('|'), 'gi')
      return ((item.pressmark ? item.pressmark + ' - ' : '') + item.title).replaceAll(highlightRegexp, (word) => '<span style="color:#3B93AF;">' + word + '</span>')
    },

    listProjectClassofocators() {
      api.smeta.projclasslist(this.project.uuid).then((data) => {
        data.forEach(e => {
          e.children = []
          e.ispc = true
        })
        this.treeList = data
      })
    },

    loadChildrens(item) {
      if (item.ispc) {
        api.smeta.topnodes(item.uuid).then((data) => {
          data = data.filter(d => d.nodeType.name != 'NODE')
          data.forEach(e => {
            delete e.children
            if (e.nodeType.name == 'TOPIC') e.children = []
            if (e.nodeType.name != 'TOPIC' && e.nodeType.name != this.nodeType.name) e.disabled = true
            if (this.compounds.some(el => el.node && el.node.uuid == e.uuid)) e.disabled = true
          })
          item.children = data
          if (!this.openedNodes.includes(item.uuid)) this.openedNodes.push(item)
        })
      } else {
        api.smeta.childrennodesfast(item.uuid, item.projectClassificatorUuid).then((data) => {
          data.forEach(e => {
            delete e.children
            if (e.nodeType.name == 'TOPIC') e.children = []
            if (e.nodeType.name != 'TOPIC' && e.nodeType.name != this.nodeType.name) e.disabled = true
            if (this.compounds.some(el => el.node && el.node.uuid == e.uuid)) e.disabled = true
          })
          item.children = data
          if (!this.openedNodes.includes(item.uuid)) this.openedNodes.push(item)
        })
      }
    },

    selectItem(item){
      if (item.nodeType && item.nodeType.name == this.nodeType.name) {
        this.activeItem = []
        this.activeItem.push(item)
      }
    },

    handleSave(){
      if (this.$refs.compoundForm.validate() && this.compound.node) {
        this.$emit('addCompound', this.compound)
      } else {
        if (!this.compound.node) this.nodeError = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.mh300 {
  height: calc(75vh - 434px);
}

::v-deep .v-treeview-node__label {
  white-space: wrap !important;
}

</style>