<template lang="pug">
  div.px-4.py-2
    v-form( ref="form" )
      div.mt-2
        app-text-field( v-model.trim="localAttribute.name" @change='saveEditAttributeModel()' :disabled='!hasClassificatorControlUniversalAttr' :rules="[rules.required]"
                        outlined dense required :label="$t('section.classification.attribute.nameAttribute')" )
      div.d-flex
        app-select.flex( v-model="localAttribute.powerType" @change='saveEditAttributeModel()' :disabled='!hasClassificatorControlUniversalAttr' :items="conclusionPowers" item-value="value" item-text="nameRu" 
                        :label="$t('section.classification.attribute.degree')" outlined dense return-object )
        app-select.flex.ml-4( v-model="localAttribute.unitType" @change='saveEditAttributeModel()' :disabled='!hasClassificatorControlUniversalAttr' :items="conclusionUnits" item-value="value" item-text="nameRu"
                        :label="$t('section.classification.menuClassification.unit')" outlined dense return-object )
      div.d-flex.mt-6
        v-switch.mr-2.pt-2( v-model="localAttribute.hasDefault" color="accent" :disabled='!hasClassificatorControlUniversalAttr' @change='isFocus(), saveEditAttributeModel()' dense light )
        app-text-field( v-model="localAttribute.defaultValue" ref="textField" @blur='isFilled' :disabled="!localAttribute.hasDefault || !hasClassificatorControlUniversalAttr"
                        :label="$t('form.formFields.defaultValue')" outlined dense )
  
    app-text.mb-2.text {{ $t('section.classification.attribute.fillAttributesPriority') }}:
    matching-attributes-form( v-model='localAttribute.matchingAttributes' @input="saveEditAttributeModel()" @createAttributeModel="createAttributeModel" )

    .d-flex.align-center.justify-space-between.mt-4
      v-btn.px-0( text color="accent" :disabled='!hasClassificatorControlUniversalAttr || editModelAttribute || editingMode' light x-small @click="showAddAttributeModelDialog" )
        v-icon.mr-1 mdi-plus-circle-outline
        span {{ $t('section.classification.attribute.addModelAttribute') }}
        
      v-icon( color="grey" :disabled='!hasClassificatorControlUniversalAttr' @click="$emit('toggle')" ) {{ showPanel ? "mdi-eye" : "mdi-eye-off" }}

</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import MatchingAttributesForm from './MatchingAttributesForm.vue'
import { $_validate_rules } from '@/common/ValidateRules'

export default {
  name: 'attribute-form',

  mixins: [$_validate_rules],

  components: {
    MatchingAttributesForm,
  },

  props: {
    attribute: {
      type: Object,
      required: true 
    },
    units: {
      typeof: Object,
      required: true
    },
    powers: {
      typeof: Object,
      required: true
    }
  },

  data(vm){
    return {
      dialog: {
        addAttributeModelDialog: false,
      },
      localAttribute: {
        ...vm.attribute
      },
    }
  },

  watch: {
    attribute(){
      this.localAttribute = {...this.attribute}
    },
  },

  computed: {
    ...mapState('projectDict', ['isTreeInPreparation', 'showPanel', 'editModelAttribute', 'editingMode', 'copyMatchingAttributes']),
    ...mapGetters("projectPermissions", ['hasClassificatorControlUniversalAttr']),

    conclusionUnits() {
      let _this = this
      return this.units.filter((item, index) => index < this.units.length - 2).map(u => {
        if (u.unitName) {
          if (typeof _this.$t(`notify.unit.name.${u.unitName}`) ===  "object") u.nameRu = _this.$t(`notify.unit.name.${u.unitName}.default`)
          else u.nameRu = _this.$t(`notify.unit.name.${u.unitName}`)
        } 
        else u.nameRu =  _this.$t('app.select.withoutType')

        return u
      })
    },

    conclusionPowers () {
      let _this = this
      return this.powers.map(u => {
        u.nameRu = u.prefixName ? _this.$t(`notify.power.name.${u.prefixName}`) : _this.$t('app.select.withoutType')
        return u
      })
    },

  },

  methods: {
    ...mapActions('projectDict', ['editAttribute', 'setSelectedProjectDict']),
    ...mapMutations('projectDict', ['setEditModelAttribute']),

    showAddAttributeModelDialog() {
      if(!this.editModelAttribute){
        this.setEditModelAttribute(true)
      } else {
        this.setEditModelAttribute(false)
      }
    },

    createAttributeModel(logicGroup) {
      const attribute = {
        name: '',
        priority: this.localAttribute.matchingAttributes.length,
        logicGroup: logicGroup
      }
      this.localAttribute.matchingAttributes.push(attribute)
      this.saveEditAttributeModel()
      this.setEditModelAttribute(false)
    },

    isFilled(){
      const attribute = Object.assign({}, this.localAttribute)
      setTimeout(() => {
        if(!attribute.defaultValue){
          attribute.hasDefault = false
        }
        this.saveEditAttributeModel(attribute)
      }, 100);
    },

    isFocus() {
      if(this.localAttribute.hasDefault === true){
        this.$nextTick(() => {
          this.$refs.textField.$el.querySelector('input').focus();
        });
      }
    },

    saveEditAttributeModel(attr) {
      const attribute = {
        ...(attr || this.localAttribute),
      }
      this.editAttribute(attribute)
    },

    resetValidationForm () {
      this.$refs.form.resetValidation()
    },
  }
}
</script>

<style lang="scss" scoped>
.text {
  color: rgba(0, 0, 0, 0.38) !important;
  text-overflow: ellipsis;
}

.flex {
  flex: 1;
}
</style>