<template lang="pug">
v-menu(offset-y :close-on-content-click="false" open-on-hover light left)
  template(v-slot:activator="{ on, attrs }")
    v-btn.dict-button.px-1(color="accent" dense text x-small v-bind="attrs" v-on="on" test-id="smeta-filter-btn")
      v-icon.mr-1( color="accent" size="16" left ) mdi-filter
      | {{$t("section.classification.menuClassification.filters")}}
  slot  
</template>

<script>
export default {
  
}
</script>