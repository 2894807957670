<template lang="pug">
div( style="height: 100%" )
  div(style="height: 100%")
    .d-flex.mb-2.justify-space-between
      v-btn.dict-button.px-1( color="accent" dense text x-small @click="toggleMassFolding" test-id="smeta-expand-collapse")
        v-icon.mr-1( color="accent" size="16" left ) unfold_more
        | {{ areAllTreeNodesOpened ? $t('section.elementTree.axis.collapseTree') : $t('section.elementTree.axis.expandTree') }}
      smeta-tree-filter-menu
        smeta-tree-filter-list( :oRules="oRules" :oValidRule="oValidRule" @update:oRules="oRules = $event" @update:oValidRule="oValidRule = $event" )
    app-text.text(v-if="!treeAllVisibleNodes || !treeAllVisibleNodes.length && !showOnlyRules") {{ $t('section.classification.tree.absence') }}
    app-text.text(v-else-if="!treeAllVisibleNodes || !treeAllVisibleNodes.length && showOnlyRules") {{ $t('section.classification.NoResult.selectedFilters') }}
    div( ref="smeta-treeview-container" style="height: 100%")
      virtual-list.fill-height.overflow-auto.base-scroll-body(
        ref='virtual-list'
        class='node-list'
        :keeps="treeElementKeeps",
        :estimate-size="treeElementHeight"
        :data-key="'uuid'"
        :data-component="smetaTreeItemComponent"
        :data-sources="treeAllVisibleNodes || []"
        :extra-props="{showMenu: showItemMenu, scrollTo: scrollTo, menu: nodeMenu}"
        test-id="smeta-virtual-list"
      )

  app-menu(ref="itemMenu" :menu="nodeMenu" @close="closeItemMenu")
  v-snackbar(v-if="copiedRule" v-model="copySnackBar" :multi-line="true" timeout="2000" color="green") 
    div {{$t('section.classification.menuClassification.copiedRule')}}
    div(test-id="copied-rule-title") {{ copiedRule.title }}
    template(v-slot:action="{ attrs }")
      v-btn(color="red" icon v-bind="attrs" @click="cancelCopyRule" :title="$t('section.classification.clipboard.cancel')")
        v-icon mdi-close

  smeta-delete-rule-dialog( v-if="deleteRule.rule" v-model="deleteRule.dialog" :rule="deleteRule.rule" )
  export-smeta-dialog( v-model="dialog.exportSmeta" :node="exportSmeta.node" )
  export-grand-smeta-dialog( v-model="grandSmeta.dialog" :error="grandSmeta.error" )
  bind-smeta-to-task-dialog( v-model="dialog.bindingTask" ref="bindTaskDialog" :node="selectedClassificationNode")
  smeta-position-dialog(v-model="dialog.smetaPosition" v-if="smetaPosition" :grandSmetaPosition="smetaPosition" :header="$t('section.classification.menuClassification.budgetItem')")
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import SmetaTreeItem from "./SmetaTreeItem.vue"
import VirtualList from 'vue-virtual-scroll-list'
import SmetaTreeFilterMenu from '../filters/SmetaTreeFilterMenu.vue'
import SmetaTreeFilterList from '../filters/SmetaTreeFilterList.vue'

import NodesSearchedByCode from './NodesSearchedByCode.vue'
import SmetaPositionDialog from './SmetaPositionDialog.vue'
import SmetaDeleteRuleDialog from '../dialogs/SmetaDeleteRuleDialog.vue'
import ExportSmetaDialog from '../dialogs/ExportSmetaDialog.vue'
import ExportGrandSmetaDialog from '../dialogs/ExportGrandSmetaDialog.vue'
import BindSmetaToTaskDialog from '../dialogs/BindSmetaToTaskDialog.vue'

import { api } from "@/api"
import { config, $_app_server } from '@/_helpers'
import { authUserService } from '@/_services'

import DraggableVirtualList from 'vue-draggable-virtual-scroll-list'

// import { AlertService } from '@app/AlertService'

export default {
  name:'smeta-tree',

  components: {
    VirtualList,
    SmetaTreeItem,
    NodesSearchedByCode,
    SmetaPositionDialog,
    DraggableVirtualList,
    SmetaTreeFilterMenu,
    SmetaTreeFilterList,
    SmetaDeleteRuleDialog, 
    ExportSmetaDialog,
    ExportGrandSmetaDialog,
    BindSmetaToTaskDialog,
  },

  props: {
    scrollTo: String,
  },

  data() {
    return {
      menuItem: null,
      deleteRule: {
        rule:null,
        dialog: false
      },
      dialog: {
        classificatorControl: false,
        exportSmeta: false,
        bindingTask: false,
        smetaPosition: false
      },
      copySnackBar:false,
      smetaToTaskBind: {
        classificatorNodeUuid: null,
        taskUuid: null
      },
      exportSmeta:{
        node: null
      },
      grandSmeta: {
        dialog: false,
        error: null
      },
      smetaPosition: null,
      rules: {
        required: (value) => !!value || this.$t("error.require"),
        double: (value) => (!!value && /^\d+(.\d+)?$/.test(value)) || this.$t('section.worm.wholeOrFractionalNumber'),
      },
      selectedClassificationNode: null,
      smetaTreeItemComponent: SmetaTreeItem,
      treeElementHeight: 24,
      treeElementKeeps: 20
    }
  },

  async mounted() {
    if (this.tasks.length == 0) await this.loadTasks()

    const smetaTreeContainer = this.$refs['smeta-treeview-container']
    if(smetaTreeContainer != undefined){
      this.pageResizeObserver = new ResizeObserver(() => {
        this.treeElementKeeps = Math.ceil(smetaTreeContainer.clientHeight / this.treeElementHeight) + 10
      })

      this.pageResizeObserver.observe(smetaTreeContainer)
    }
  },

  computed: {
    ...mapState('smeta', ['showOnlyRules', 'showOnlyWithSmeta', 'showOnlyValidRule', 'editRuleItem', 'copiedRule','searchText', 'items', 'counts']),
    ...mapGetters('smeta', ['getTopItemCount', 'getProjectClassificator', 'treeAllVisibleNodes','areAllTreeNodesOpened',
      'getTreeCache', 'grandSmetaPositionByClassificator', 'isOpened']),

    ...mapState('project', ['project','projectSettings']),
    ...mapGetters('project', ['projectUuid']),
    ...mapGetters('projectPermissions', ['hasTaskCreate']),

    //TODO КОСТЫЛЬ - задачи без сортировки
    ...mapState('task', ['tasks']),

    ...mapGetters("projectPermissions", ['hasClassificatorCreate', 'hasClassificatorDownloadReport', 'hasClassificatorControlUniversalAttr', 'hasClassificatorCreateRule', 'hasClassificatorDeleteRule']),

    oRules: {
      get(){
        return this.showOnlyRules
      },
      set(value){
        this.setShowOnlyRules(value)
      }
    },

    oValidRule:{
      get(){
        return this.showOnlyValidRule
      },
      set(value){
        this.setShowOnlyValidRule(value)
      }
    },

    projectClassificatorObject(){
      return this.getProjectClassificator(this.projectSettings.projectClassificator)
    },

    nodeMenu() {
      let m = []
      if (this.menuItem) {
        if (this.menuItem.nodeType.name == 'TOPIC') {
          if (this.hasClassificatorDownloadReport) {
            m.push(
              { title: this.$t('section.classification.menuClassification.exportSmeta'), action: () => this.exportToGrantSmetaExel() },
              { title: this.$t('section.classification.menuClassification.exportSmetaPlugin'), action: this.showExportDialog }
            )
          }

          if (this.hasClassificatorControlUniversalAttr) {
            m.push({ title: 'section.classification.menuClassification.classifyModel', action: () => api.projectDict.classificate({projectUuid: this.projectUuid, classificatorNodeUuid: this.menuItem.uuid, projectClassificatorUuid: null}) })
          }

          if (this.hasClassificatorCreate) {
            if (m.length > 0) m.push({ divider: true })
            m.push({ title: this.$t('section.classification.menuClassification.editSection'), action: this.editItem })
          }
          
          return m
        } 
        else if (this.menuItem.nodeType.name != 'TOPIC' && this.menuItem.nodeType.name != 'RULE') {
          if (this.hasClassificatorCreateRule) {
            if (this.copiedRule) {
              m.push({ title: this.$t('section.classification.menuClassification.pasteRule'), action: this.pasteRule })
              m.push({ divider: true })
            }

            m.push({ title: this.$t('section.classification.menuClassification.addRule'), action: this.addRule })
            m.push({ divider: true })
            m.push({ title: this.$t('section.classification.menuClassification.editClass'), action: this.editItem })
            m.push({ divider: true })
          }

          if (this.hasClassificatorDownloadReport) {
            m.push({ title: this.$t('section.classification.menuClassification.uploadSmeta'), action: () => this.exportToGrantSmetaExel() })
            m.push({ title: this.$t('section.classification.menuClassification.exportSmetaPlugin'), action: this.showExportDialog })
            m.push({ divider: true })
          }

           if (this.hasClassificatorControlUniversalAttr) {
            m.push({ title: 'section.classification.menuClassification.classifyModel', action: () => api.projectDict.classificate({projectUuid: this.projectUuid, classificatorNodeUuid: this.menuItem.uuid, projectClassificatorUuid: null}) })
          }

          if (this.hasClassificatorCreate && this.hasTaskCreate) {
            m.push({ title: this.$t('section.classification.menuClassification.bindTask'), action: this.bindTask })
          }

          if (this.menuItem.grandSmetaPosition) {
            m.push({ divider: true })
            m.push({ title: this.$t('section.classification.menuClassification.showPositionInSmeta'), action: this.showSmetaPositionDialog })
          }

          return m
        } 
        else if (this.menuItem.nodeType.name == 'RULE'){
          if (this.hasClassificatorCreateRule) {
            m.push({ title: this.$t('section.classification.menuClassification.copyRule'), action: () => this.copyRule() })
          }
          if (this.hasClassificatorDeleteRule) {
            m.push({ title: this.$t('section.classification.menuClassification.deleteRule'), action: this.showDelRule })
          }
          return m
        }
      }
      return m
    }
  },

  watch: {
    'dialog.smetaPosition'(v){
      if (!v) {
        this.smetaPosition = null
      }
    },

    async scrollTo() {
      this.expandAllTreeNodes()
      await new Promise((resolve) => {
        setTimeout(resolve, 10)
      })
      const scrollToIndex = this.treeAllVisibleNodes.findIndex(node => node.uuid === this.scrollTo)

      if(scrollToIndex >= 0) {
        this.$refs['virtual-list'].scrollToIndex(scrollToIndex)
      }
    }
  },

  methods: {
    ...mapMutations('smeta', ['setShowOnlyRules', 'setShowOnlyWithSmeta', 'setShowOnlyValidRule', 'setEditRuleItem', 'setCopiedRule']),
    ...mapActions('smeta', ['expandAllTreeNodes', 'collapseAllTreeNodes', 'expandToRules', 'addChildElement', 'removeElementByUuid', 'updateElement', 'toggleOpened']),
    ...mapMutations('project', ['setTopUnit']),
    ...mapActions('task', ['loadTasks', 'getFirstTaskBySmetaNode']),


    toggleMassFolding () {
      this.areAllTreeNodesOpened ? this.collapseAllTreeNodes() : this.expandAllTreeNodes()
    },

    collapseAll(){
      this.collapseAllTreeNodes()
    },

    expandAll(){
      this.expandAllTreeNodes()
    },

    expandRules(){
      this.expandToRules()
    },

    showItemMenu(event, item) {
      this.menuItem = item
      if (this.nodeMenu.length > 0) this.$refs.itemMenu.show(event)
    },

    closeItemMenu() {
      this.menuItem = null
    },

    editItem(){
      if (this.hasClassificatorCreate) {
        let item = this.menuItem
        this.$emit('editItem',item)
      }
    },

    // RULE ACTIONS
    addRule(){
      if (this.hasClassificatorCreateRule) {
        let item = this.menuItem
        let rule = {
          title: this.$t('section.classification.menuClassification.newRule'),
          classificatorNode: {uuid:item.uuid}
        }

        api.smeta.addNodeRule(item.uuid, rule, item.projectClassificatorUuid).then(data => {
          this.setEditRuleItem(null)
          let promise = this.addChildElement({ parentUuid: item.uuid, element: data, elemType:'RULE' })
          promise.then((value) => this.setEditRuleItem(value))
        })
      }
    },

    showDelRule(){
      if (this.hasClassificatorDeleteRule) {
        this.deleteRule.rule = this.menuItem
        this.deleteRule.dialog = true
      }
    },

    copyRule() {
      if (this.hasClassificatorCreateRule) {
        let item = this.menuItem;
        this.setCopiedRule(item);
        this.copySnackBar = true;

        setTimeout(() => {
          this.copySnackBar = false;
        }, 3000); // Ограничение времени в миллисекундах (в данном случае 3 секунды)
      }
    },

    cancelCopyRule(){
      if (this.hasClassificatorCreateRule) {
        this.setCopiedRule(null)
        this.copySnackBar = false
      }
    },

    pasteRule(){
      if (this.hasClassificatorCreateRule) {
        let itemUuid = this.menuItem.uuid
        let projectClassificatorUuid = this.menuItem.projectClassificatorUuid
        api.smeta.copyNodeRule(itemUuid,this.copiedRule.uuid,projectClassificatorUuid).then(data => {

          this.setEditRuleItem(null)
          let promise = this.addChildElement({ parentUuid: itemUuid, element: data, elemType:'RULE' })
          promise.then((value) => this.setEditRuleItem(value))
        }).catch(error => {
          console.log(error)
          console.log(error.message)
        })
      }
    },
    // RULE ACTIONS

    // EXPORTINGS
    showExportDialog(){
      this.dialog.exportSmeta = true
      this.exportSmeta.node = this.menuItem
    },

    exportToGrantSmetaExel(){
      this.grandSmeta.error = null
      let item = this.menuItem
      this.grandSmeta.dialog = true

      let send = () => {
        $_app_server({
          url: `${config.apiUrl}smeta/node/exportexcel?projectUuid=${this.project.uuid}&nodeUuid=${item.uuid}`,
          method: 'GET',
          responseType: 'blob'
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${item.title}.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.remove()
          this.grandSmeta.dialog = false
        }).catch( (error) => {
          if (error.response !== undefined) {
            console.log(error.response.status)
            if (error.response.status !== 401){
              this.grandSmeta.error = error
            }

            if (error.response.status === 401){
              authUserService.refreshToken().then(() => {
                send()
              }).catch( (error) => {
                console.log(error)
                console.log(error.response)
              })
            }
          }
        })
      }
      send()
    },

    bindTask () {
      this.selectedClassificationNode = this.menuItem
      this.$nextTick(() => {
        this.$refs.bindTaskDialog.show()
      })
    },

    showSmetaPositionDialog() {
      this.smetaPosition = this.menuItem.grandSmetaPosition
      this.dialog.smetaPosition = true
    },
  }
}
</script>

<style scoped>
  .node-list {
    height: calc(99% - 74px);
  }
  .overflow-auto {
    overflow-x: hidden !important;
  }
  .text {
    color: rgba(0, 0, 0, 0.38) !important;
    text-overflow: ellipsis;
    font-size: 14px;
    margin-left: 5px;
  }
</style>