<template lang="pug">
app-dialog( v-model="$_show" :header="dialogHeader" :width="500" :confirmText=" $t('button.share')" @confirm="shareToOrg" )
  app-section.base-scroll-body.share-section--height
    app-text Список проектов организации:
      .d-flex.align-center( v-for="item in organizationProjects " )
        v-checkbox.share-smeta--checkbox( v-model="item.selected" hide-details light :ripple="false" dense )
        .share-smeta--title {{ item.title }}
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { api } from "@/api"
import DialogToggable from "@/components/app/DialogToggable"

export default({ 
  mixins: [DialogToggable],

  data() {
    return {
      selectedProjects: null,
    }

  },

  computed: {
    ...mapState('project', ['project']),
    ...mapState('smeta', ['organizationProjects', 'projectClassificatorUuid']),

    dialogHeader() {
      return this.$t('section.classification.menuClassification.shareClassificator') + ' ' + this.project.organization?.title
    }
  }, 

  methods: {
    ...mapActions('smeta', ['LOAD_PROJECT_CLASSIFICATORS']),

    shareToOrg(){
      this.selectedProjects = this.organizationProjects.filter(item => item.selected).map(item => item.uuid)
      api.smeta.sharetoorg(this.projectClassificatorUuid, this.project.org, this.selectedProjects).then(() => {
        this.LOAD_PROJECT_CLASSIFICATORS(this.project.uuid)
        this.$_show = false
      })
    },
  }
})
</script>

<style scoped>
.share-smeta--checkbox {
  text-align: start;
  margin: 0;
  font-size: 10px;
}

.share-smeta--title {
  margin-top: 2px;
}

.share-section--height {
  max-height: 500px;
}
</style>