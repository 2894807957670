<template lang="pug">
app-dialog-confirm( v-model="$_show" :header="$t('section.classification.menuClassification.deleteRule')" @confirm="deleteRule" )
  app-section
    app-text {{$t('section.classification.menuClassification.deleteRule')}} "{{ rule.title }}"?
</template>

<script>
import WormNode from '@/assets/model/worm/WormNode'
import DialogToggable from '@/components/app/DialogToggable'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { api } from "@/api"

export default {
  name: 'SmetaDeleteRuleDialog', 
  mixins: [DialogToggable],

  props: {
    rule: {
      type: Object, 
      default: null
    }
  },

  computed: {
    ...mapState('smeta', ['editRuleItem']),
    ...mapGetters("projectPermissions", ['hasClassificatorDeleteRule']),
  },

  methods: {
    ...mapMutations('smeta', ['setEditRuleItem']),
    ...mapActions('smeta', ['removeElementByUuid']),

    deleteRule() {
      if (this.hasClassificatorDeleteRule) {
        api.smeta.delNodeRule(this.rule.uuid).then(() => {
          if (this.editRuleItem && this.editRuleItem.uuid == this.rule.uuid) this.setEditRuleItem(null)
          this.removeElementByUuid(this.rule.uuid)

          let wormNode = WormNode.query().where('classificatorFindRuleUuid', this.rule.uuid).get()
          for (let wnode of wormNode) {
            WormNode.update({where: wnode.uuid, data: {classificatorFindRule: null, classificatorNode: null}})
          }
        })
      }
    }
  }
}
</script>