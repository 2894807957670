import { groupBy } from "lodash"
import { SmetaMergeElementState, SmetaMergedElement } from "./SmetaMergedElement.js"
export class SmetaMergedElementsCache {
  constructor() {
    this.map = new Map()
    this.opened = new WeakSet()
    this.acceptedList = new WeakSet()
    this.defaultAcceptedList = new WeakSet()
    this.statesCount = {
      DELETE: 0, // Удаленные
      OK: 0, // Общие
      INSERT: 0, // Добавленные
      UPDATE: 0 // Измененные
    }
    this.mapSize = 0
    this.checkedFlag = false
  }

  toggleNode(itemKey) {
    let flag = false
    if (this.opened.has(this.map.get(itemKey))) {
      flag = false
      this.opened.delete(this.map.get(itemKey))
    } else {
      flag = true
      this.opened.add(this.map.get(itemKey))
    }
    this.toggleNodeByChildren(this.map.get(itemKey).children, this, flag)
  }

  toggleNodeByChildren(list = [], ctx, flag) {
    if (list.length) {
      list.forEach(item => {
        let el = ctx.map.get(item)
        el.toggleVisibility(flag)
        if (!flag) {
          this.opened.delete(el)
          el.toggleExpanded(false)
          ctx.toggleNodeByChildren(el.children, ctx, flag)
        }
      })
    }
  }

  expandAll() {
    if (this.map.size) {
      this.map.forEach(item => {
        item.toggleVisibility(true)
        item.toggleExpanded(true)
      })
    }
    this.opened = new WeakSet()
    Array.from(this.map.entries()).filter(item => item[1].isNode).forEach(item => {
      this.opened.add(this.map.get(item[0]))
    })
  }

  expandItem(itemKey) {
    this.map.get(itemKey).toggleExpanded(!this.map.get(itemKey).expanded)
  }

  collapseAll() {
    if (this.map.size) {
      this.map.forEach(item => {
        if (item.parentUuid) {
          item.toggleVisibility(false)
        }
        item.toggleExpanded(false)
      })
      this.opened = new WeakSet()
    }
  }

  applyAcceptance(itemKey, elementState) {
    if (elementState == SmetaMergeElementState.DELETE) {
      this.applyDeleteAcceptance(itemKey)
    } else {
      this.applyDefaultAcceptance(itemKey, elementState)
    }
  }

  applyDeleteAcceptance(itemKey) {
    let flag = false
    if (this.acceptedList.has(this.map.get(itemKey))) {
      flag = false
      this.acceptedList.delete(this.map.get(itemKey))
    }
    else {
      flag = true
      this.acceptedList.add(this.map.get(itemKey))
    }
    this.map.get(itemKey).toggleAcceptance(flag)
    this.applyByDeleteChildren(this.map.get(itemKey).children, flag)
  }

  applyByDeleteChildren(list = [], flag) {
    if (list.length) {
      list.forEach(item => {
        if (flag) this.map.get(item).toggleDisable(true)
        else this.map.get(item).toggleDisable(false)
        if (this.map.get(item).children.length) this.applyByDeleteChildren(this.map.get(item).children, flag)
      })
    }
  }

  applyDefaultAcceptance(itemKey, elementState) {
    elementState
    this.map.get(itemKey).toggleAcceptance(!this.map.get(itemKey).accepted)
    let flag = this.map.get(itemKey).accepted
    this.applyToParent(this.map.get(itemKey).parentUuid, flag)
    this.applyToChildren(this.map.get(itemKey).children, flag)
  }

  applyToChildren(list = [], flag) {
    if (list.length) {
      list.forEach(item => {
        this.map.get(item).toggleAcceptance(flag)
        if (this.map.get(item).children.length) this.applyToChildren(this.map.get(item).children, flag)
      })
    }
  }

  applyToParent(parentUuid, flag) {
    if (parentUuid) {
      let childs = this.map.get(parentUuid).children.find(item => this.map.get(item).accepted == true)
      if (childs) this.map.get(parentUuid).toggleAcceptance(true)
      else this.map.get(parentUuid).toggleAcceptance(false)
      this.applyToParent(this.map.get(parentUuid).parentUuid, flag)
    }
  }

  acceptMerge(itemKey) {
    for (let i = itemKey + 1; i < this.map.size; i++) {
      if (this.map.get(i).element.level <= this.map.get(itemKey).element.level) {
        break;
      }
      this.map.get(i).toggleAcceptance()
    }
  }

  setList(list = []) {
    SmetaMergedElement.rootElements = 0
    SmetaMergedElement.totalVisible = 0
    const group = groupBy(list, 'state')
    Object.keys(group).forEach(key => {
      this.statesCount[key] = group[key].length
    })

    let cahce = {}
    let currentItem = null
    let state = {}
    this.map = new Map(list.map((item) => {
      if (item.level == 0) {
        state[item.uuid] = new Set()
        currentItem = item
      }
      if (item.parentUuid) {
        let arr = cahce[item.parentUuid] ?? []
        cahce[item.parentUuid] = arr.concat([item.uuid])
      }
      if (currentItem && currentItem?.state == SmetaMergeElementState.OK && item.state != SmetaMergeElementState.OK) {
        state[currentItem.uuid].add(item.state)
      }
      return [item.uuid, new SmetaMergedElement(item)]
    }))
    Object.keys(state).forEach((uuid) => {
      this.map.get(uuid).setStates(Array.from(state[uuid].map(el => ({ element: { state: el } }))))
    })

    this.mapSize = this.map.size
    SmetaMergedElement.allElements = this.mapSize
    SmetaMergedElement.totalVisible = SmetaMergedElement.rootElements
    this.fillChildren(cahce)
  }

  fillChildren(items) {
    Object.keys(items).forEach(key => {
      this.map.get(key).setChildren(items[key])
    })
  }

  getMapSize() {
    return this.mapSize
  }
}