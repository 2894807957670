<template lang="pug">
app-dialog( v-model="$_show" :header="windowTitle" width="80%" complex )
  app-panel-header( :menu="grandSmetaMenu" )
  app-section.grand-smeta-section.base-scroll-body( :rounded="false" )
    div( v-if="grandSmeta" )
      div( v-for="chapter in grandSmeta.chapters" :key="chapter.uuid" )
        app-text {{ chapter.caption }}
        div.ml-5.mb-5( v-for="position in chapter.positions" :key="position.uuid" )
          grand-smeta-position( :item="position" )

          div.ml-5( v-for="resource in position.resources" :key="resource.uuid" )
            grand-smeta-resource( :item="resource" )

    div( v-else )
      app-text {{ $t('section.elementTree.smetaclassifier') }}
      app-button-add.mt-3( width="100%" :text="$t('button.upload')" @click="showUpload" )

  grand-smeta-upload( v-model="dialog.grandSmetaUpload" :projectClassificatorUuid="projectClassificatorUuid" )

  app-dialog-confirm( v-model="dialog.grandSmetaDelete"
                      :header="$t('section.classification.menuClassification.grandSmeta.confirmDeleteSmeta')"
                      @confirm="deleteSmeta" @close="closeDeleteSmetaWindow" )
    app-section.section-smeta {{ deleteSmetaText }} ?
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex"
import { api } from "@/api"

import DialogToggable from "@/components/app/DialogToggable"

import GrandSmetaUpload from './GrandSmetaUpload.vue'
import GrandSmetaPosition from './GrandSmetaPosition.vue'
import GrandSmetaResource from './GrandSmetaResource.vue'

export default {
  name:'GrandSmeta',

  mixins: [DialogToggable],

  components: {
    GrandSmetaUpload,
    GrandSmetaPosition,
    GrandSmetaResource
  },

  data() {
    return {
      dialog: {
        grandSmetaUpload: false,
        grandSmetaDelete: false
      }
    }
  },

  computed: {
    ...mapState('project', ['project']),
    ...mapState('smeta', ['projectClassificators', 'projectClassificatorUuid','grandSmeta']),
    ...mapGetters('smeta',['getNode']),

    windowTitle(){
      if (this.grandSmeta) {
        if (this.grandSmeta.projectClassificatorUuid) return this.$t('section.classification.menuClassification.grandSmeta.smetaFor') + this.project.title
        let cls = this.projectClassificators.find(e => e.uuid == this.projectClassificatorUuid)
        if (cls) return this.$t('section.classification.menuClassification.grandSmeta.smetaFor') + cls.title
        return this.$t('section.classification.menuClassification.grandSmeta.classifierError')
      }
      return this.$t('section.classification.menuClassification.showSmeta')
    },

    deleteSmetaText() {
      if (this.grandSmeta?.projectClassificatorUuid) return this.$t('section.classification.menuClassification.grandSmeta.checkConfirmDeleteSmeta') + " " + this.project.title
      return ''
    },

    grandSmetaMenu(){
      let act = [
        { title: this.$t('section.classification.menuClassification.grandSmeta.uploadSmeta'), action: this.showUpload }
      ]
      if (this.grandSmeta) act.push({ title: this.$t('section.classification.menuClassification.grandSmeta.deleteSmeta'), action: this.showDelete })
      return act
    }
  },

  methods: {
    ...mapMutations('smeta',['setGrandSmeta']),

    hasNode(classificatorNodeUuid) {
      return this.getNode(classificatorNodeUuid) ? true : false
    },

    showUpload(){
      this.dialog.grandSmetaUpload = true
    },

    showDelete(){
      this.dialog.grandSmetaDelete = true
    },

    deleteSmeta(){
      api.smeta.deleteGrandSmeta(this.grandSmeta.uuid).then(() => {
        this.setGrandSmeta(null)
        this.dialog.grandSmetaDelete = false
      })
    },

    closeDeleteSmetaWindow() {
      this.dialog.grandSmetaDelete = false
    },
  }
}
</script>

<style scoped>
  .grand-smeta-section {
    max-height: 524px;
  }

  .section-smeta-buttons {
    margin-left: 185px;
    margin-top: 20px;
  }

  .section-smeta {
    font-size: 14px;
    padding-top: 15px;
    height: 70px;
    color: black;
  }

  .delete-smeta {
    margin-left: 10px;
  }
  
</style>