<template lang="pug">
  div
    div.bar-rule-text(v-if="!editMode" @click="switchEditMode" :class="{'pointer': !disabled, 'gray-filter':disabled}")
      div(v-html="logicGroupTitle")
    v-card(v-show="editMode && !disabled" light)
      v-card-text
        div.d-flex.align-center(v-if='isIfcql' style= 'right: 8px; left: 8px;' )
          app-textarea.mr-2.full-height.field(v-model='ifcQlCode' outlined auto-grow label="" hide-details style =' height: 50%; ')
          
        logic-group.mx-n2(v-if='!isIfcql' :logicGroup="editLogicGroup" ref="logicGroup" :default-class="false" :style='{ "visibility": isIfcql ? "hidden" : "visible" }' lockable @copy='copyCondition' :disabledFormula="true")
      //- v-card-actions
      v-spacer.d-flex.align-center.pa-2.flex-between
        div
          v-btn(color="" fab small depressed light @click='isIfcql = !isIfcql')
            v-icon mdi-code-tags
        div
          v-btn.text-none.dialog-confirm-action.mr-2(fab small depressed light @click="editMode = !editMode" :style='{ "visibility": isIfcql ? "hidden" : "visible" }') 
            v-icon mdi-close
          v-btn.text-none.dialog-confirm-action.ml-auto(fab color="accent" small depressed light test-id="check-logic-group-btn" @click="checkLogicGroup()" :style='{ "visibility": isIfcql ? "hidden" : "visible"} ')
            v-icon mdi-check
          v-btn.text-none.dialog-confirm-action.ml-auto( v-if="isIfcql && editMode"  color="accent" small depressed light fab @click='sendIfcQl' :style='{ "visibility": isIfcql ? "visible" : "hidden" }')
            v-icon mdi-check
        
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import LogicGroup from '../../../components/logicGroup/LogicGroup'

import RuleConditionValid from '@/assets/model/condition/RuleConditionValid'
import ElementRuleConditionValid from '@/assets/model/condition/ElementRuleConditionValid'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { api } from '@/api'

let lo = {
  'AND':'И',
  'OR':'ИЛИ',
  'NOT':'НЕ'
}

export default {
  components: {
    LogicGroup
  },

  props: {
    value: Object,
    disabled: {
      type: Boolean,
      default: false
    },
    ruleUuid: String
  },

  data() {
    return {
      editMode: false,
      editLogicGroup: this.value ? JSON.parse(JSON.stringify(this.value)) : {},

      logicgroups: {},
      ifcQlCode: null,
      isIfcql: false,
    }
  },

  mounted() {
    
  },

  computed: {
    ...mapGetters('project', ['projectUuid']),
    ...mapState('smeta', ['checkLogicMode']),

    pickedElement() {
      return XeokitMediator.ElementsSelection.pickedElement
    },

    ruleConditionValid(){
      return RuleConditionValid.find(this.ruleUuid)
    },

    elementRuleConditionValid(){
      return ElementRuleConditionValid.find([this.ruleUuid, this.pickedElement])
    },
    

    newValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input',value)
        this.$emit('change',value)
      }
    },

    logicGroupTitle() {
      if (this.value && 
        (this.value && this.value.children && this.value.children.length > 0)
        || (this.value && this.value.condition && this.value.condition.length > 0)
      ) return this.makeLogicGroupText(this.value, "", "", null)

      return this.$t('section.classification.menuClassification.setCondition')
    }
  },

  watch: {
    value(v, ov) {
      if ( ov == null || v == null || JSON.stringify(v) != JSON.stringify(ov)) this.editMode = false
    }
  },

  methods: {

    async sendIfcQl() {
      let str = this.ifcQlCode
      // str = str.replace(/(["'])/g, '\\$1');
      
      await api.ifcql.ifcQlToLogicGroup(this.projectUuid, str).then((lg) => {
        this.newValue = lg
        // TODO ADD LOGICGROUP
      })
    },

    switchEditMode() {
      api.ifcql.logicGroupToifcQl(this.projectUuid, this.newValue).then((ifcql) => { 
        this.ifcQlCode = ifcql
      })
      if (!this.disabled){
        this.editLogicGroup = this.value ? JSON.parse(JSON.stringify(this.value)) : {}
        this.editMode = true
      }
    },

    checkLogicGroup() {
      let lg = this.$refs.logicGroup.makeLogicGroup()
      if (lg) {
        this.newValue = lg
        this.editMode = false
      }
    },

    makeLogicGroupText(logicGroup, start, end, prevOperatorType) {
      let text = ''
      let rows = []
      logicGroup.condition.forEach(cond => rows.push(cond))
      if (logicGroup.children) {
        logicGroup.children.forEach(lg => rows.push(lg))
      }
      rows.sort((a, b) => a.sortOrder - b.sortOrder)

      
      rows.forEach((row, index) => {
        if(row.logicOperatorType !== undefined) {
          if(rows.length==1) text += this.makeLogicGroupText(row, start+"(", end+")", prevOperatorType)
          else if(index==0) text += this.makeLogicGroupText(row, start+"(", ")", logicGroup.logicOperatorType)
          else if(index<rows.length-1) text += this.makeLogicGroupText(row, "(", ")", logicGroup.logicOperatorType)
          else text += this.makeLogicGroupText(row, "(", end+")", prevOperatorType)
        } else {
          
          if(index==0){
            text += ' '+start
            start=''
          }

          let cClass = ''
          
          let validy = null
          if (this.checkLogicMode == 0) {
            validy = this.ruleConditionValid?.conditionsValid.find(e => e.conditionUuid == row.uuid);
          } else if (this.checkLogicMode == 1 && this.pickedElement) {
            validy = this.elementRuleConditionValid?.conditionsValid.find(e => e.conditionUuid == row.uuid);
          }

          if (validy && validy.valid) cClass = 'condition-validy-valid'
          else if (validy && !validy.valid) cClass = 'condition-validy-invalid'

          text += '<span class="'+cClass+'">'
          

          let op = row.operator.title.toLowerCase()
          let originalRp = row.title.substring(row.title.indexOf(op) + op.length + 1)
          let rp = (op === 'элемент из набора') 
          ? `<span style="display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; color:#eb5757;">${originalRp}</span>`
          : `<span style="color:#eb5757;">${originalRp}</span>`
          let t = row.title.replace(originalRp, '<span style="color:#eb5757;">"' + rp + '"</span>')
          t = t.replace(row.operator.title.toLowerCase(),'<span style="color:#9b51e0;">'+this.replaceOperator(row.operator)+'</span>')
          text += t

          text += '</span>'

          if(index==rows.length-1) {
            text += end
            end=''
          }

          if((index==0 || index<rows.length-1) && logicGroup.logicOperatorType!=null) {
            if (!logicGroup.logicOperatorType.title) logicGroup.logicOperatorType.title = lo[logicGroup.logicOperatorType.value]
            text += ' <span style="color:#9b51e0;">'+logicGroup.logicOperatorType.title.toLowerCase()+'</span> '
          } else if(prevOperatorType!=null && prevOperatorType.title) text +=  ' <span style="color:#9b51e0;">'+prevOperatorType.title.toLowerCase()+'</span> '
        }
      })

      return text
    },

    replaceOperator(o) {
      switch(o.name){
        case 'EQ': return '='
        case 'NOTEQ': return '!='
        case 'GT': return '>'
        case 'LT': return '<'
        case 'GTEQ': return '>='
        case 'LTEQ': return '<='
        default: return o.title
      }
    },

    copyCondition(condition) {
      this.$emit('copy', condition)
    },

    pasteConditions() {
      this.$refs['logicGroup'].pasteConditions()
    }
  }
}
</script>

<style scoped>

  .bar-rule-text {
    font-size:14px;
    background: #f0f0f0;
    padding:5px;
    color:#505254;
  }
  .gray-filter {
    filter:grayscale(50%);
  }

  .field >>> textarea {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin; 
    resize: none;
    min-height: 56px;
    max-height: 128px;
    margin: 0 !important;
  }
  .flex-between {
    justify-content: space-between;
  }
</style>

<style scoped lang="sass">
.full-height ::v-deep .v-input__control,
.full-height ::v-deep .v-input__slot
  height: 100%
</style>

