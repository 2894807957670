<template lang="pug">
.smeta-merged-tree__wrap-item( :style="`min-height: 24px`" )
  .d-flex.align-center.wrap-item__title
    app-icon-button( v-if="isNode" 
                     :style="getOffset" 
                     :icon="source.expanded ? 'mdi-menu-down' : 'mdi-menu-right'" 
                     iconSize="16px" 
                     buttonSize="24px" 
                     @click="handleToggleNode(source.uuid)" )

    .wrap-item__title( :style="getTitleOffset" ) {{ source.element.code }} {{ source.element.title.oldValue }}
  .d-flex.align-center
    app-tooltip( v-for="el in source.states" bottom :title="smetaMerge.getTranslate(el)" )
      app-icon-button( :iconColor="smetaMerge.getColor(el.element.state)" :icon="smetaMerge.getIcon(el)" @click="handleClick(source, el.element.state)" )
    app-tooltip( v-if="!source.disabled" bottom :title="smetaMerge.getTranslate(source)"  )
      app-icon-button( v-if="!source.disabled" 
                        :iconColor="smetaMerge.getColor(source.element.state)" 
                        :icon="smetaMerge.getIcon(source)" 
                        @click="handleClick(source, source.element.state)" )
    app-tooltip( v-if="smetaMerge.getAlertTitle(source)" bottom :title="smetaMerge.getAlertTitle(source)" )
      app-icon-button( iconColor="red" icon="$information-box-outline-icon" )
</template>

<script>
import { SmetaMerge } from './utils.js'
import { useSmetaMergeStore } from '@/pinia/smeta/smetaMerge.store'

const smetaMergeStore = useSmetaMergeStore()
export default {
  props: {
    source: {
      type: Object,
      default () {
        return {}
      }
    },
    handleToggleNode: Function,
    handleClick: Function
  },

  computed: {
    smetaMerge() {
      return SmetaMerge
    },

    getOffset() {
      if(smetaMergeStore.smetaMergeSearchText) return ''
      return `margin-left: ${ this.source.element.level * this.smetaMerge.calcOffset(this.source.element.type) }px;`
    },

    getTitleOffset() {
      if(smetaMergeStore.smetaMergeSearchText) return 'margin-left: 8px;'
      return this.smetaMerge.getMarginByLevel(this.source)
    },

    isNode() {
      return this.source.isNode && this.source.children.length && !smetaMergeStore.smetaMergeSearchText
    }
  }
}
</script>

<style scoped>
.smeta-merged-tree__wrap-item {
  color: #000;
  display: flex;
  gap: 10px;
  font-size: 12px;
  height: 24px;
  justify-content: space-between;
}
.wrap-item__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.smeta-icon-warning {
  color: #FF5C00;
}
</style>