<template lang="pug">
.rounded.black--text.pa-2.text-body-2.link-card__container-border
  .d-flex.flex-column.mb-3( v-if="linkInfo.isRule" )
    .font-weight-bold Правило: <span class="font-weight-regular">{{ $t(linkInfo.smetaTreeItem.title) }}</span>
  .d-flex.flex-column.mb-3( v-else )
    .font-weight-bold Класс: <span class="font-weight-regular">{{ $t(linkInfo.smetaTreeItem.title) }}</span>
    .font-weight-bold Тип класса: <span class="font-weight-regular">{{ $t(linkInfo.smetaTreeItem.nodeType.title) }}</span>
  .d-flex.flex-column( style="gap: 10px;" )
    .d-flex( v-for="link in linkInfo.links" )
      v-chip.pl-1.pr-1.mr-2( label small color="#e5e5e5" light ) {{ link.typeName }}
      .d-flex.justify-space-between( style="flex: 1" )
        a( :class="link.archived ? 'link-card__router-text-empty' : 'link-card__router-text'" 
           @click="openLink(link)" ) {{ link.project.title }}/{{ link.title }}
        v-chip.pl-1.pr-1( v-if="link.archived" outlined x-small label small color="#eb5757" ) Удалена
</template>

<script>
import { SmetaLinkItem } from '@/pinia/smeta/SmetaConnections.model';

export default {
  props: {
    linkInfo: SmetaLinkItem
  },

  methods: {
    openLink(link) {
      this.$emit('openLink', link)
    }
  }
}
</script>

<style scoped>
.link-card__container-border {
  border: 1px solid #e0e0e0;
}

.link-card__router-text {
  color: var(--v-accent-base);
  text-decoration: none;
}

.link-card__router-text-empty {
  color: black;
  text-decoration: none;
  cursor: default;
}
</style>