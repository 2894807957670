import { defineStore } from 'pinia'
import { api } from '@/api'
import { SmetaMergeElementState, SmetaMergedElement } from "@/assets/model/smeta/merge/SmetaMergedElement"
import { SmetaMergedElementsCache } from "@/assets/model/smeta/merge/SmetaMergedElementsCache"

// TODO: Вынести
export const useSmetaMergeStore = defineStore('smeta.merge', {
  state: () => ({
    mergedItemsLength: 0,
    isClassificatorUpdating: false,
    filters: [],
    currentMerge: null,
    smetaMergeSearchText: null,
    accumulator: 0, // TODO: Исправить костыльное решение с реактивностью
    smetaMergedCache: new SmetaMergedElementsCache()
  }),

  getters: {
    // MERGED ELEMENTS CACHE
    getMergedElements: (state) => {
      state.accumulator
      let toShow = []
      if (state.smetaMergeSearchText) {
        if (state.filters.length) {
          toShow = Array.from(state.smetaMergedCache.map.values()).filter(item => state.filters.find(filter => item.element.state == filter) && item.element.code?.startsWith(state.smetaMergeSearchText))
        } else {
          toShow = Array.from(state.smetaMergedCache.map.values()).filter(item => item.element.code?.startsWith(state.smetaMergeSearchText))
        }
      } else {
        if (state.filters.length) {
          toShow = Array.from(state.smetaMergedCache.map.values()).filter(item => item.visible && state.filters.find(filter => item.element.state == filter))
        } else {
          toShow = Array.from(state.smetaMergedCache.map.values()).filter(item => item.visible)
        }
      }
      return toShow
    },

    getMergedStates: (state) => {
      state.accumulator
      return state.smetaMergedCache.statesCount
    },

    isAnyElementAccepted: (state) => {
      state.accumulator
      return SmetaMergedElement.totalAccepted
    }
  },

  actions: {
    setClassificatorUpdatingFlag(flag){
      this.isClassificatorUpdating = flag
    },

    setFilters(filters) {
      this.accumulator++
      if (!filters.length) this.mergedItemsLength = this.smetaMergedCache.getMapSize()
      else this.mergedItemsLength = filters.reduce((acc, curr) => (acc + this.smetaMergedCache.statesCount[curr]), 0)
      this.filters = filters
    },

    setMergeItemsLength(len) {
      this.mergedItemsLength = len
    },

    setSmetaMergeSearchText(value) {
      this.accumulator++
      this.smetaMergeSearchText = value
    },

    toggleNode(itemKey) {
      this.accumulator++
      this.smetaMergedCache.toggleNode(itemKey)
    },

    expandAll() {
      this.accumulator++
      this.smetaMergedCache.expandAll()
    },

    collapseAll() {
      this.accumulator++
      this.smetaMergedCache.collapseAll()
    },

    applyAcceptance(item) {
      if (item.elementState != SmetaMergeElementState.OK) {
        this.accumulator++
        this.smetaMergedCache.applyAcceptance(item.itemKey, item.elementState)
      }
    },

    expandItem(itemKey) {
      this.accumulator++
      this.smetaMergedCache.expandItem(itemKey)
    },

    mergeVersions(newVersion) {
      this.resetState()
      this.setClassificatorUpdatingFlag(true)
      this.currentMerge = newVersion
      return api.smeta.mergeClassificator(newVersion).then(data => {
        this.setClassificatorUpdatingFlag(false)
        this.smetaMergedCache.setList(data)
        this.mergedItemsLength = SmetaMergedElement.rootElements
        this.accumulator++
      }).catch(error => {
        console.log(error)
        this.setClassificatorUpdatingFlag(false)
      })
    },

    applyMergeVersions() {
      this.setClassificatorUpdatingFlag(true)
      const items = Array.from(this.smetaMergedCache.map.values()).filter(item => item.accepted).map(item => item.element)
      const applyMerge = { ...this.currentMerge, items: items }
      return api.smeta.applyMergeClassificator(applyMerge).then(() => {
        this.resetState()
      }).catch(error => {
        console.log(error)
        this.setClassificatorUpdatingFlag(false)
      })
    },

    resetState() {
      this.smetaMergedCache = new SmetaMergedElementsCache()
      this.mergedItemsLength = 0
      this.isClassificatorUpdating = false
      this.filters = []
      this.currentMerge = null
      this.smetaMergeSearchText = null
    }
  }
})