<template lang="pug">
  app-dialog( v-model="show" :header="$t('section.classification.attribute.addDict')" :confirm-text="$t('button.add')" @confirm="_createDict" :confirmDisabled="!dictName.trim()" )
      app-section
        app-text-field( v-model="dictName" :label="$t('section.classification.attribute.nameDict')" outlined )
</template>

<script>
export default {
  name: 'add-project-dict-dialog',

  props: {
    value: Boolean,
  },

  data(){
    return{
      dictName: '',
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set() {
        this.close()    
      }
    },
  },

  methods: {
    close() {
      this.$emit('input', false)
    },
    _createDict() {
      this.$emit('createDict', this.dictName)
      this.dictName = ''
      this.close()
    }
  }

}
</script>

<style>

</style>