<template lang="pug">
.fill-height
  .d-flex.flex-column.fill-height
    .d-flex.mb-2.justify-space-between
      v-btn.dict-button.px-1( color="accent" dense text x-small test-id="smeta-merge-expand-collapse" @click="handleToggleAll" )
        v-icon.mr-1( color="accent" size="16" left ) unfold_more
        | {{ areAllTreeNodesOpened ? $t('section.elementTree.axis.expandTree') : $t('section.elementTree.axis.collapseTree') }}
      smeta-tree-filter-menu
        smeta-update-tree-filter-list

    .mb-4( style="height: calc(100% - 125px)" )
      v-divider.mb-1.mt-1( horizontal )
      .fill-height( ref="smeta-merged-list-container" )
        virtual-list.fill-height.overflow-auto.base-scroll-body(
          class='node-list'
          :keeps="treeElementKeeps",
          :estimate-size="treeElementHeight"
          :data-key="'uuid'"
          :data-component="smetaMergedTreeItemComponent"
          :data-sources="getMergedElements"
          :extra-props="{ handleToggleNode: handleToggleNode, handleClick: handleClick }"
        )
    v-btn.dict-button.mb-3.mr-2.ml-2.accept-button_position( :disabled="!isAnyElementAccepted" x-small color="accent" @click="applyMerge" ) Применить изменения
</template>

<script>
import SmetaMergedTreeItem from './SmetaMergedTreeItem.vue'
import SmetaUpdateTreeFilterList from '../filters/SmetaUpdateTreeFilterList.vue'
import SmetaTreeFilterMenu from '../filters/SmetaTreeFilterMenu.vue'
import SmetaMergeDialog from '../forms/SmetaMergeDialog.vue'
import { SmetaMergedElement } from '@/assets/model/smeta/merge/SmetaMergedElement.js'
import VirtualList from 'vue-virtual-scroll-list'
import { useSmetaMergeStore } from '@/pinia/smeta/smetaMerge.store.js'

const smetaMergeStore = useSmetaMergeStore()

export default {
  components: {
    SmetaUpdateTreeFilterList,
    SmetaTreeFilterMenu,
    SmetaMergeDialog,
    VirtualList
  },

  props: {
    classificatorUuid: null
  },

  data() {
    return {
      smetaMergedTreeItemComponent: SmetaMergedTreeItem,
      treeElementHeight: 24,
      treeElementKeeps: 20,
    }
  },

  computed: {
    mergedItemsLength() { return smetaMergeStore.mergedItemsLength },
    getMergedElements() { return smetaMergeStore.getMergedElements },
    isAnyElementAccepted() { return smetaMergeStore.isAnyElementAccepted },
    areAllTreeNodesOpened() {
      if (smetaMergeStore.mergedItemsLength == SmetaMergedElement.rootElements) return true
      if (SmetaMergedElement.totalVisible < SmetaMergedElement.allElements ) return true
      return false
    },
  },

  methods: {
    handleToggleNode(key) {
      smetaMergeStore.toggleNode(key)
      smetaMergeStore.setMergeItemsLength(SmetaMergedElement.totalVisible)
      smetaMergeStore.expandItem(key)
    },

    handleToggleAll() {
      this.areAllTreeNodesOpened ? smetaMergeStore.expandAll() : smetaMergeStore.collapseAll()
      smetaMergeStore.setMergeItemsLength(SmetaMergedElement.totalVisible)
    },

    handleClick(item, elementState) {
      smetaMergeStore.applyAcceptance({ itemKey: item.uuid, elementState: elementState })
    },

    applyMerge() {
      smetaMergeStore.applyMergeVersions().then(() => {
        this.$emit('closeMerge')
      })
    },

    resize() {
      const smetaTreeContainer = this.$refs['smeta-merged-list-container']
      if(smetaTreeContainer != undefined){
        this.pageResizeObserver = new ResizeObserver(() => {
          this.treeElementKeeps = Math.ceil(smetaTreeContainer.clientHeight / this.treeElementHeight) + 10
        })
        this.pageResizeObserver.observe(smetaTreeContainer)
      }
    }
  },

  mounted() {
    if (smetaMergeStore.mergedItemsLength) {
      this.resize()
    }
  }
}
</script>

<style scoped>
.overflow-auto {
  overflow-x: hidden !important;
}
</style>