<template lang="pug">
app-dialog( v-model="$_show" :header="$t('section.classification.attribute.addBinding')" width="700" @close="clearSelectedClassificatorNode")
  app-section
    div.d-flex
      app-select(
        v-model="projectClassificatorsSelected"
        :items="projectClassificators"
        @input="projectClassificatorHandle"
        item-text="title"
        item-value="uuid"
        flat
        solo
        dense
        outlined
        :label="$t('section.classification.menuClassification.selectClassifier')")
      app-text-field.ml-2( :value="searchClassificatorNode"
                           @input="handleInput($event)"
                           :label="$t('section.classification.menuClassification.search')" 
                           hide-details
                           outlined 
                           dense 
                           clearable)

    v-form
      div.height.base-scroll-body
        div( v-if="loading" )
          v-skeleton-loader(type="list-item-two-line" light)
          v-progress-linear(indeterminate color="accent")

        .d-flex( v-if="classificators.length")
            v-btn.px-1( color="accent" dense text x-small @click="toggleAll")
              v-icon.mr-1( color="accent" size="16" left ) unfold_more
              | {{ expand ? $t('section.elementTree.axis.collapseTree') : $t('section.elementTree.axis.expandTree') }}

        app-treeview-with-search.__project_view_tree(
          ref="classificatorNodeTree"
          dense
          light
          hoverable
          open-on-click
          item-key="uuid"
          :itemSearchFor="(item) => item.pressmark + item.title"
          :itemHighlighted="(item) => (item.pressmark ? item.pressmark + ' - ' : '') + item.title"
          :items="classificators"
          :search="searchClassificatorNode"
          return-object)
          template(v-slot:label="{ item, highlighted }")
            div.text-truncate( @click="classificatorNodeHandle(item)" :class="itemClass(item)" v-html="highlighted")
      
      v-divider.my-2(light)
      .d-flex.justify-end
        app-button(@click="addClassificatorNode" color="accent" :disabled="disabled") {{ $t('button.add') }} 
          
</template>

<script>
import { api } from "@/api"
import { mapState } from 'vuex'
import DialogToggable from "@/components/app/DialogToggable"
import _ from "lodash";

export default {
  name: 'classificator-node-form-dialog',

  mixins: [DialogToggable],

  data(){
    return {
      projectClassificators: [],
      classificators: [],
      projectClassificatorsSelected: null,
      selectedClassificatorNode: [],
      disabled: true,
      searchClassificatorNode: '',
      loading: false,
      expand: false,
      handleInput: _.debounce((val) => {
        this.searchClassificatorNode = val 
      }, 400),
    }
  },

  watch: {
    searchClassificatorNode(val) {
      this.expand = !!val
      ; this.$refs.classificatorNodeTree.updateAll(!!val)
    }
  },

  mounted() {
    this.listProjectClassofocators()
  },

  computed: {
    ...mapState("project", ["project"]),
    ...mapState('projectDict', ['selectedAttribute']),

    itemClass() {
    return (item) => {
      const isDisabled = this.selectedAttribute?.classificationType?.find(node => node.valueUuid === item.uuid);
      const isActive = this.selectedClassificatorNode.find(node => node.uuid === item.uuid);
      
      return {
        'disabled': !!isDisabled,
        'activ': !!isActive
      };
    };
  }

  },

  methods: {
    listProjectClassofocators() {
      api.smeta.projclasslist(this.project.uuid).then((data) => {
        this.projectClassificators = data
      })
    },

    projectClassificatorHandle() {
      this.classificators = []
      this.loading = true
      api.smeta.loadFulltreeWithRules(this.projectClassificatorsSelected).then((data) => {
        this.classificators = filterTree(data)
        this.loading = false
        this.expand = false
      })
    },

    classificatorNodeHandle(item) {
      if(!item.children.length){
        const index = this.selectedClassificatorNode.findIndex(node => node.uuid === item.uuid)
        if(index === -1){
          this.selectedClassificatorNode.push(item)
        }
        else{
          this.selectedClassificatorNode.splice(index, 1)
        }
        this.disabled = false
      }
      else {
        this.disabled = true
      }
    },

    addClassificatorNode() {
      let nodes = []
      for (let node of this.selectedClassificatorNode) {
        let classificatorNode = {
          system: this.projectClassificators.find(e => e.uuid == this.projectClassificatorsSelected).title,
          value: node.pressmark
        }
        nodes.push(classificatorNode)
      }
      this.$emit('addClassificatorNode', nodes)
      this.clearSelectedClassificatorNode()  
    },

    clearSelectedClassificatorNode() {
      this.selectedClassificatorNode = []
      this.projectClassificatorsSelected = null
      this.classificators = []
    },

    toggleAll(){
      this.expand = !this.expand
      this.$refs.classificatorNodeTree.updateAll(this.expand)
    },
    
  },
}
function filterTree(tree) {
  const result = []

  tree.forEach(node => {
    if(node.rules?.length) {
      result.push(node)
    }
    else if(node.children.length) {
      const childrenWithRules = filterTree(node.children)

      if(childrenWithRules.length) {
        node.children = childrenWithRules
        result.push(node)
      }
    }
  })
  return result
}
</script>

<style lang="scss" scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.activ {
  background-color: var(--v-primary-lighten5);
}

.height {
  height: calc(75vh - 360px)
}

.text-truncate{
  white-space: wrap !important;
}
</style>