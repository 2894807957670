<template lang="pug">
app-dialog( v-model="$_show" :header="$t('section.classification.menuClassification.exportSmetaSimple')" )
  v-form( ref="exportSmetaForm" @submit.prevent="getSmetaExport" )
    app-section
      app-select( v-model="plugin" :items="smetaPlugins" item-text="name" item-value="uuid"
                  :label="$t('section.classification.menuClassification.plugin')" clearable :rules="rulesRequired" )      
      .d-flex.justify-end.mt-2
        app-button.mr-2( cancel @click="$_show = false" ) {{ $t('module.process.cancel') }}
        app-button( action type="submit" ) {{ $t('button.send') }}
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable'
import { mapGetters, mapState } from 'vuex'
import { api } from "@/api"
import { AlertService } from '@app/AlertService'

export default {
  name: 'ExportSmetaDialog', 
  mixins: [DialogToggable],

  props: {
    node: {
      type: Object, 
      default: null
    }
  },

  data() {
    return {
      plugin: null,

      exportSmeta: {
        loading: true,
        model: false,
        wmm: false,
      },

      rules: {
        required: (value) => !!value || this.$t("error.require"),
      },
    }
  },

  computed: {
    ...mapState('project', ['project']),
    ...mapGetters('plugins', ['smetaPlugins']),
  
    rulesRequired () {
      return [this.rules.required]
    },
  },

  methods: {
    getSmetaExport() {
      let rq = {
        nodeUuid: this.node.uuid,
        pluginUuid: this.plugin,
        projectUuid: this.project.uuid,
        showModelStats: this.exportSmeta.model,
        showWorkMatMeh: this.exportSmeta.wmm
      }

      if (this.$refs.exportSmetaForm.validate()) {
        AlertService.info({info: this.$t('section.classification.menuClassification.addToProcess')})
        api.smeta.exportSmeta(rq)
        this.$_show = false
      }
    },
  }
}
</script>