<template lang="pug">
v-list
  v-list-item
   v-checkbox( v-model="filter.OK" dense @change="setFilter" )
      template( v-slot:label )
        .smeta-tree-filter-list__text Общие - ({{ getMergedStates.OK }})
  v-list-item
    v-checkbox( v-model="filter.INSERT" dense @change="setFilter" )
      template( v-slot:label )
        .smeta-tree-filter-list__text Добавления - ({{ getMergedStates.INSERT }})
  v-list-item
    v-checkbox( v-model="filter.UPDATE" dense @change="setFilter" )
      template( v-slot:label )
        .smeta-tree-filter-list__text Изменения - ({{ getMergedStates.UPDATE }})
  v-list-item
    v-checkbox( v-model="filter.DELETE" dense @change="setFilter" )
      template( v-slot:label )
        .smeta-tree-filter-list__text Удаления - ({{ getMergedStates.DELETE }})
</template>

<script>
import { useSmetaMergeStore } from '@/pinia/smeta/smetaMerge.store';

const smetaMergeStore = useSmetaMergeStore()

export default {
  props: {
    oRules: null,
  },

  data() {
    return {
      filter: {
        DELETE: false,
        INSERT: false,
        UPDATE: false,
        OK: false
      }
    }
  },

  computed: {
    getMergedStates() { return smetaMergeStore.getMergedStates }
  },

  methods: {
    setFilter() {
      smetaMergeStore.setFilters(Object.keys(this.filter).filter(key => this.filter[key]))
    }
  }
}
</script>

<style scoped>
.smeta-tree-filter-list__text {
  color:#3b93af;
  font-size: 10px;
  text-transform: uppercase;
}
</style>